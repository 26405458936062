import React, { Component } from "react";
import emailjs from "emailjs-com";
import { SiGooglehangoutsmeet } from "react-icons/si";

class ContactOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rnName: "",
      rnEmail: "",
      rnSubject: "",
      rnMessage: "",
      buttonStatus: true,
      ok: false,
    };
  }

  onSendDataContact = (e) => {
    e.preventDefault();
    console.log(this.state);

    this.setState({ buttonStatus: false });

    //https://dashboard.emailjs.com/history
    const templateParams = {
      //to_email: "u6000324@unimilitar.edu.co",
      to_email: "julian.orlando.orduz@gmail.com",
      names: this.state.rnName,
      mail_contact: this.state.rnEmail,
      subject: this.state.rnSubject,
      message: this.state.rnMessage,
      //date_js: new Date()
    };
    emailjs
      .send(
        "zoho",
        "mail_contact",
        templateParams,
        "user_uOPIEhpv03f0M6ah3GSsf"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          this.setState({
            rnName: "",
            rnEmail: "",
            rnSubject: "",
            rnMessage: "",
            ok: true,
          });
        },
        (err) => {
          console.log("FAILED...", err);
          alert("Ha ocurrido un error, inténtelo más tarde.");
        }
      );
  };

  render() {
    return (
      <div style={{ position: "relative" }}>
        <h2
          style={{ color: "#0ef9a0", width: "100%", textAlign: "center" }}
          className="title"
        >
          {this.props.lenguage.contactMe.title}
        </h2>
        <img
          style={{
            width: "100%",
            objectFit: "contain",
          }}
          src="assets/images/contact.JPG"
          alt=""
        />
        <div className="photo_contact">
          <a
            className="button_contact"
            href="https://calendly.com/julianorduz/30min"
            target="_black"
          >
            <SiGooglehangoutsmeet size={24} />{" "}
            {this.props.lenguage.contactMe.buttonText}
          </a>
        </div>
      </div>
    );
  }
}
export default ContactOne;
