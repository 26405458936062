import React, { Component } from "react";
import { FiX, FiMenu, FiFileText } from "react-icons/fi";
import Scrollspy from "react-scrollspy";
import { isMobile } from "react-device-detect";
import { FaLinkedinIn } from "react-icons/fa";

class HeaderThree extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.stickyHeader = this.stickyHeader.bind(this);

    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }

  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }

  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }

  stickyHeader() {}

  render() {
    window.addEventListener("scroll", function () {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector(".header--fixed").classList.add("sticky");
      } else {
        document.querySelector(".header--fixed").classList.remove("sticky");
      }
    });

    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }
    const { color = "default-color" } = this.props;
    return (
      <header
        style={{ backgroundColor: "black" }}
        className={`header-area header-style-two header--fixed ${color}`}
      >
        <div className="header-wrapper">
          <div className="header-left d-flex align-items-center">
            <div className="logo">
              <a href={this.props.homeLink}>
                <img
                  src="/assets/images/logoJulian_verde.png"
                  alt="Julian Orduz"
                  style={{ width: 60 }}
                />
              </a>
            </div>
            <nav className="mainmenunav d-lg-block ml--50">
              <Scrollspy
                className="mainmenu"
                items={["home", "about_me", "projects", "contact"]}
                currentClassName="is-current"
                offset={-200}
              >
                <li>
                  <a href="#home" onClick={this.CLoseMenuTrigger}>
                    {this.props.lenguage.menu[0]}
                  </a>
                </li>
                <li>
                  <a href="#about_me" onClick={this.CLoseMenuTrigger}>
                    {this.props.lenguage.menu[1]}
                  </a>
                </li>
                <li>
                  <a href="#projects" onClick={this.CLoseMenuTrigger}>
                    {this.props.lenguage.menu[2]}
                  </a>
                </li>
                <li>
                  <a href="#contact" onClick={this.CLoseMenuTrigger}>
                    {this.props.lenguage.menu[3]}
                  </a>
                </li>
                {isMobile && (
                  <li>
                    {
                      <a href="/#">
                        <select
                          className="lenguage-select-mobile"
                          name="select"
                          onChange={(x) =>
                            this.props.changeLenguage(x.target.value)
                          }
                        >
                          <option value="es">
                            {this.props.lenguage.spanish}
                          </option>
                          <option value="en" selected>
                            {this.props.lenguage.english}
                          </option>
                        </select>
                      </a>
                    }
                  </li>
                )}
              </Scrollspy>
            </nav>
          </div>

          <div className="header-right">
            {!isMobile && (
              <div className="icons">
                <div style={{ cursor: "pointer" }} className="col inner">
                  <FaLinkedinIn
                    size={22}
                    color="white"
                    onClick={() =>
                      window.open(
                        "https://www.linkedin.com/in/julian-orduz/",
                        ""
                      )
                    }
                  />
                </div>
                <div style={{ cursor: "pointer" }} className="col inner">
                  <FiFileText
                    color="white"
                    size={22}
                    onClick={() =>
                      window.open(
                        process.env.PUBLIC_URL + "Orduz_Julian_ResumeCV.pdf",
                        ""
                      )
                    }
                  />
                </div>
              </div>
            )}
            {!isMobile && (
              <>
                <select
                  className="lenguage-select"
                  name="select"
                  onChange={(x) => this.props.changeLenguage(x.target.value)}
                >
                  <option value="es">{this.props.lenguage.spanish}</option>
                  <option value="en" selected>
                    {this.props.lenguage.english}
                  </option>
                </select>
              </>
            )}
            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span
                onClick={this.menuTrigger}
                className="menutrigger text-white"
              >
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default HeaderThree;
