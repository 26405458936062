const en = {
  titles: ["Tech Entrepreneur", "Leader", "Volunteer", "Engineer"],
  menu: ["Home ", "About me", "Ventures", "Let´s talk"],
  aboutme: {
    title: "About me",
    text: "Colombian passionate about innovation and tech entrepreneurship, with a background in Engineering and a constant drive to make a social impact. An operations expert, with over 7 years of experience as a tech Founder.<br/>Currently, he is the Founder and CEO of Uptopus AI, a startup aimed at helping SMBs grow through a platform that provides AI-powered automated marketing strategies.<br/>Founder of startups, including Copywrite, Golky, and coronaviruscol.com. Through Copywrite Educación, he has revolutionized education, benefiting over 15,000 students in more than 19 countries. During the COVID-19 pandemic, he led the creation of coronaviruscol.com, a platform that assisted over 50,000 patients, earning government recognition.<br/>Recognized as one of the Young Leaders of the Americas (YLAI Fellowship) by the U.S. Department of State and supported by leading accelerators like Platzi Startups and Cube Ventures. He spearheaded a project as project manager for the LDT master's program at Georgetown University in Washington D.C.",
  },
  ventures: {
    title: "Ventures",
    text: "I've led teams through the conception, launch, and scaling of the following Startups.",
    subItem: [
      {
        title: "Uptopus AI",
        text: "Uptopus is a startup based on artificial intelligence for small businesses. It provides and refines marketing strategies through a co-pilot that understands the business. Using a SaaS model, it is 10X more affordable and 10X faster.",
      },
      {
        title: "Copywrite",
        text: "EdTech venture that operates as a two-sided marketplace, akin to an 'Uber for education'. We swiftly connect students with specialized tutors in under 5 minutes. To date, we've assisted over 7,500 students across 8 countries.",
      },
      {
        title: "Golky",
        text: "Sports-Tech that enhances amateur sports tournaments with real-time technology. Organizers can easily manage events, while players and spectators stay informed. To date, we've supported +250 tournaments with +5,500 registered players.",
      },
      {
        title: "Coronaviruscol",
        text: "HealthTech that revitalizes Colombia's health system. We provide rapid at-home COVID-19 risk assessments in just 5 minutes, having assessed over 50,000+ users to date.",
      },
    ],
    buttonText: "Detail",
  },
  contactMe: {
    title: "Let's talk",
    subTitle: "To schedule a meeting, please fill out the following details.",
    input1: "Your Name *",
    input2: "Your Email *",
    input3: "Subject *",
    input4: "Why do you want to schedule the meeting?",
    buttonText1: "Send",
    buttonText2: "Loading...",
    buttonText: "Schedule a meeting",
  },
  english: "English",
  spanish: "Spanish",
};

const es = {
  titles: ["Emprendedor Tech", "Lider", "Voluntario", "Ingeniero"],
  menu: ["Inicio", "Acerca de mí", "Startups", "Hablemos"],
  aboutme: {
    title: "Acerca de mí",
    text: "Colombiano apasionado por la innovación y el emprendimiento tech, con formación en Ingeniería y siempre buscando generar impacto social. Experto en operaciones, con más de 7 años de experiencia como Founder tech.<br/>Actualmente, es el Fundador y CEO de Uptopus AI, una startup que busca ayudar a las PYMEs a crecer mediante una plataforma que ofrece estrategias de marketing automatizadas por inteligencia artificial.<br/>Ha fundado otras startups, incluyendo Copywrite, Golky y coronaviruscol.com. A través de Copywrite Educación, ha transformado la educación, beneficiando a más de 15,000 estudiantes en más de 19 países. Durante la pandemia de COVID-19, lideró la creación de coronaviruscol.com, una plataforma que ayudó a más de 50,000 pacientes, obteniendo reconocimiento del gobierno.<br/>Reconocido como uno de los Jóvenes Líderes de América (YLAI Fellowship) por el Departamento de Estado de EE. UU. y respaldado por aceleradoras líderes como Platzi Startups y Cube Ventures. Lideró un proyecto como gerente de proyecto para el programa de maestría LDT de la Universidad de Georgetown en Washington D.C.",
  },
  ventures: {
    title: "Startups",
    text: "He liderado equipos en la creación, lanzamiento y escalamiento de las siguientes Startups.",
    subItem: [
      {
        title: "Uptopus AI",
        text: "Uptopus es una startup basada en inteligencia artificial para pequeñas empresas. Provee y perfecciona estrategias de marketing a través de un copiloto que comprende el negocio. Utiliza un modelo SaaS. 10X más económico y 10X más rápido.",
      },
      {
        title: "Copywrite",
        text: "EdTech que funciona como un two-sided marketplace, similar a un 'Uber para la educación'. Conectamos a estudiantes con tutores especializados en menos de 5 minutos. A la fecha, hemos ayudado a más de 15,000 estudiantes en 9 países",
      },
      {
        title: "Golky",
        text: "SportsTech que potencia torneos deportivos amateurs con tecnología en tiempo real. Los organizadores pueden gestionar eventos, mientras que jugadores y fans acceden a la información. A la fecha, hemos respaldado más de 250 torneos con más de 5,500 jugadores registrados.",
      },
      {
        title: "Coronaviruscol",
        text: "Healthtech que revitaliza el sistema de salud de Colombia. Ofrecemos evaluaciones rápidas del riesgo de COVID-19 en casa en solo 5 minutos, hemos evaluado a más de 50,000 pacientes a la fecha.",
      },
    ],
    buttonText: "Detalle",
  },
  contactMe: {
    title: "Conversemos",
    subTitle:
      "Para agendar una reunión conmigo, por favor llena los siguientes datos.",
    input1: "Tu nombre *",
    input2: "Tu correo electrónico: *",
    input3: "Asunto *",
    input4: "¿Por qué quieres tomar la reunión?",
    buttonText1: "Enviar",
    buttonText2: "Cargando...",
    buttonText: "Agendar un reunión",
  },
  english: "Inglés",
  spanish: "Español",
};

module.exports = {
  en,
  es,
};
