import React, { useState } from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import ContactOne from "../elements/contact/ContactOne";
import PortfolioList from "../elements/portfolio/PortfolioList";
import { es, en } from "../lenguages";
import { isMobile } from "react-device-detect";

const PortfolioLanding = () => {
  const [lenguage, setLenguage] = useState(en);

  const changeLenguage = (len) => {
    if (len === "es") setLenguage(es);
    if (len === "en") setLenguage(en);
  };

  return (
    <div className="active-dark">
      <Helmet pageTitle="Julian Orduz" />

      <HeaderThree
        homeLink="/"
        color="color-white"
        lenguage={lenguage}
        changeLenguage={changeLenguage}
      />

      {/* Start Slider Area   */}
      <div
        className="slider-activation slider-creative-agency with-particles"
        id="home"
      >
        <div className="bg_image bg_image--27">
          <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center">
            <div className="container">
              <div className="row">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                  className="col-lg-6 col-md-12"
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      placeContent: "center",
                    }}
                    className={`inner text-center title_mobile`}
                  >
                    <h1
                      style={{
                        textAlign: "center",
                        fontSize: "62px",
                      }}
                    >
                      Julian Orduz
                    </h1>
                    <h2 style={{ color: "#0ef9a0", fontSize: 52 }}>
                      <TextLoop>
                        {lenguage.titles.map((el, index) => {
                          return (
                            <span
                              style={
                                isMobile ? { fontSize: index === 0 && 24 } : {}
                              }
                            >
                              {el}
                            </span>
                          );
                        })}
                      </TextLoop>
                    </h2>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                  className="col-lg-6 col-md-12"
                >
                  <img
                    style={{
                      width: "70%",
                      objectFit: "contain",
                      borderRadius: "2rem",
                    }}
                    src="assets/images/photo_1.jpeg"
                    alt="julian_photo"
                  />
                </div>
              </div>
              <div className="row" style={{ textAlign: "center" }}>
                {/*<div className="col inner" style={{ padding: 5 }}>
                  <MdEmail
                    color={"white"}
                    size={50}
                    style={styleIcon}
                    onClick={() =>
                      window.open("mailto:julian@copywritecol.com", "")
                    }
                  />
                  </div>*/}
                {/*/<div className="col inner" style={{ padding: 5 }}>
                  <FaWhatsapp
                    color={"white"}
                    size={50}
                    style={styleIcon}
                    onClick={() =>
                      window.open(
                        "https://api.whatsapp.com/send?phone=+573123916788",
                        ""
                      )
                    }
                  />
                </div>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Slider Area   */}

      <div id="about_me">
        <div className="container about_me_contianer">
          {!isMobile && (
            <h2
              style={{
                color: "#0ef9a0",
                width: "100%",
                textAlign: "start",
                fontSize: "52px",
                padding: "0 2rem",
              }}
              className="title about_me_title"
            >
              {lenguage.aboutme.title}
            </h2>
          )}
          <div className="about_me_text">
            {lenguage.aboutme.text.split("<br/>").map((item) => {
              if (item.split("<a>").length > 1) {
                return (
                  <p>
                    {item.split("<a>").map((i, index) => {
                      if (index % 2 === 0) {
                        return i;
                      }
                      return (
                        <a
                          style={{ color: "#0ef9a0" }}
                          href={i}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {i}
                        </a>
                      );
                    })}
                  </p>
                );
              }
              return <p>{item}</p>;
            })}
          </div>
          <div className="about_me_img">
            <img
              className="photo_about_me"
              src="assets/images/about_me_image.JPG"
              alt="about me imgage"
            />
          </div>
          {isMobile && (
            <h2
              style={{
                color: "#0ef9a0",
                width: "100%",
                textAlign: "center",
                fontSize: "52px",
              }}
              className="title about_me_title"
            >
              {lenguage.aboutme.title}
            </h2>
          )}
        </div>
      </div>

      {/* Start Portfolio Area */}
      <div id="projects" className="fix">
        <div className="portfolio-area ptb--120 bg_color--1">
          <div className="portfolio-sacousel-inner">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                    <h2 style={{ color: "#0ef9a0" }} className="title">
                      {lenguage.ventures.title}
                    </h2>
                    <p>{lenguage.ventures.text}</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <PortfolioList
                  lenguage={lenguage}
                  styevariation="text-center mt--40"
                  column="col-lg-3 col-md-6 col-sm-6 col-12"
                  item="6"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Portfolio Area */}

      {/* Start Contact Area */}
      <div id="contact" className="fix">
        <div className="rn-contact-area ptb--120 bg_color--1">
          <ContactOne lenguage={lenguage} />
        </div>
      </div>
      {/* End Contact Area */}

      <FooterTwo />
      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </div>
  );
};

export default PortfolioLanding;
