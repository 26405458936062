import React, { Component } from "react";
import { isMobile } from "react-device-detect";

const PortfolioListContent = [
  {
    bgColor: "#5938ff",
    letterColor: "#fff",
    title: "CopyWrite",
    detail:
      "Compañía de ayuda academica a universitarios, hemos trabajado con más de 1000 estudiantes.",
    image: "iconoUptopus.png",
    link: "https://uptopus.vercel.app/",
  },
  {
    bgColor: "#f9bb0f",
    letterColor: "#fff",
    title: "CopyWrite",
    detail:
      "Compañía de ayuda academica a universitarios, hemos trabajado con más de 1000 estudiantes.",
    image: "logoCopywrite.png",
    link: "https://www.copywritecol.com/",
  },
  {
    bgColor: "#ff3c3c",
    letterColor: "#fff",
    title: "Golky",
    detail:
      "Startup en construcción que permite que futbolistas amateurs se sientan como profesionales.",
    image: "logoGolky.png",
    link: "https://golky.co/",
  },
  {
    bgColor: "#0f175e",
    letterColor: "#fff",
    title: "CoronavirusCol",
    detail:
      "Plataforma que oxigena el sistema de salud colombiano mediante consultas médicas online y gratuitas.",
    image: "logoCoronavirus.png",
    link: "https://coronaviruscol.com/",
  },
];

class PortfolioList extends Component {
  render() {
    const { column, styevariation, lenguage } = this.props;
    const list = PortfolioListContent.slice(0, this.props.item);
    return (
      <React.Fragment>
        {list.map((value, index) => (
          <div className={`${column}`} key={index}>
            <div className={`portfolio ${styevariation}`}>
              <div
                className="thumbnail-inner"
                style={{ backgroundColor: value.bgColor }}
              ></div>
              <div className="content">
                <img
                  src={`/assets/images/${value.image}`}
                  alt="Logo images"
                  style={{
                    width: isMobile ? 100 : 135,
                    zIndex: 10,
                    position: "relative",
                  }}
                />
                <br />
                <br />
                <div className="inner">
                  <h3 style={{ color: value.letterColor }}>
                    {lenguage.ventures.subItem[index].title}
                  </h3>
                  <span style={{ color: value.letterColor }}>
                    {lenguage.ventures.subItem[index].text}
                  </span>
                  <div className="portfolio-button">
                    {value.letterColor !== "#fff" ? (
                      <a
                        className="rn-btn"
                        target={"_blank"}
                        href={value.link}
                        style={{
                          color: value.letterColor,
                          borderColor: "#000",
                          textTransform: "none",
                        }}
                      >
                        {lenguage.ventures.buttonText}
                      </a>
                    ) : (
                      <a
                        className="rn-btn"
                        target={"_blank"}
                        href={value.link}
                        style={{
                          color: value.letterColor,
                          textTransform: "none",
                        }}
                      >
                        {lenguage.ventures.buttonText}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </React.Fragment>
    );
  }
}
export default PortfolioList;
